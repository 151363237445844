import { useEffect } from 'react';
import { navigateToCustomPage } from '../utils/navigate';

const NotFoundPage = () => {
  useEffect(() => {
    navigateToCustomPage('/company/287634/es-ES/error/')?.();
  }, []);

  return null;
};

export default NotFoundPage;
